<template>
  <div>
    <el-card class="box-card">
      <!-- 头部 -->
      <div class="top">
        <el-button type="danger" @click="endshan">删除车辆</el-button>
        <el-button type="primary" @click="tos">返回车辆列表</el-button>
      </div>
      <!-- 主体 -->

      <div class="head">
        <div class="head_left">
          <div class="pics">
            <el-image :src="pic"></el-image>
            <el-image :src="qrIMG"></el-image>
             <el-button @click="getRefuelingQrcode(id)">创建加油二维码</el-button>
          </div>
        </div>
        <div class="head_right">
          <div>
            <ul>
              <li>车辆牌号</li>
              <li>曾用车牌号</li>
              <li>车辆类型</li>
              <li>车辆型号</li>
              <li>车辆品牌</li>
              <li>车架号</li>
              <li>车龄</li>
              <li>车辆所有权</li>
              <li>车辆归属项目</li>
              <li>车辆使用单位</li>
              <li>责任人</li>
              <li>联系方式</li>
            </ul>
            <ul>
              <li>{{ list.auto_number }}</li>
              <li>{{ list.old_auto_numbers ? list.old_auto_numbers : '--' }}</li>
              <li>{{ list.get_use_type ? list.get_use_type.tname : '--' }}</li>
              <li>{{ list.type_no ? list.type_no : '--' }}</li>
              <li>{{ list.get_brand ? list.get_brand.name : '--' }}</li>
              <li>{{ list.frame_no ? list.frame_no : '--' }}</li>
              <li>{{ list.auto_age ? list.auto_age : '--' }}年</li>
              <li>{{ list.get_ownership ? list.get_ownership.owner_name : '--' }}</li>
              <li>{{ list.get_org ? list.get_org.node_name : '--' }}</li>
              <li>{{ list.get_current_org ? list.get_current_org.node_name : '--' }}</li>
              <li>{{ list.liableidname ? list.liableidname : '--' }}</li>
              <li>{{ list.get_liable_info ? list.get_liable_info.mobile : '--' }}</li>
            </ul>
          </div>

          <div>
            <ul>
              <li>车辆总质量</li>
              <li>车辆载质量</li>
              <li>底盘厂家</li>
              <li>上装厂家</li>
              <li>初次上牌日期</li>
              <li>购置日期</li>
              <li>购置金额</li>
              <li>车辆保险到期时间</li>
              <li>车辆保险公司</li>
              <li>车辆出险次数</li>
              <li>车辆年审到期时间</li>
            </ul>
            <ul>
              <li>{{ list.auto_weight ? list.auto_weight : '--' }}KG</li>
              <li>{{ list.capacity_weight ? list.capacity_weight : '--' }}KG</li>
              <li>{{ list.get_dipan_brand ? list.get_dipan_brand.name : '--' }}</li>
              <li>{{ list.get_shangzhuang_brand ? list.get_shangzhuang_brand.name : '--' }}</li>
              <li>{{ list.register_time ? list.register_time : '--' }}</li>
              <li>{{ list.buy_in ? list.buy_in : '--' }}</li>
              <li>{{ list.fapiao_money | money }}元</li>
              <li>{{ shijian }}</li>
              <li>{{ company }}</li>
              <li>车辆出险次数</li>
              <li>{{ list.check_time ? list.check_time : '--' }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
        <span>暂无数据</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div class="di">
        <!-- <div class="foot"> -->
        <!-- <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-button type="primary" @click="tan">车辆调动记录</el-button>
            </el-col>
            <el-col :span="8">
              <el-button type="success" @click="tan">车辆购置发票</el-button>
            </el-col>
            <el-col :span="8">
              <el-button type="info" @click="tan">维修保养记录</el-button>
            </el-col>
            <el-col :span="8">
              <el-button type="warning" @click="tan">车辆登记证书</el-button>
            </el-col>
          </el-row> -->
        <!-- <el-row type="flex" class="row-bg" justify="space-around"> -->
        <!-- <el-col :span="8">
              <el-button type="warning" @click="tan">车辆登记证书</el-button>
            </el-col> -->
        <!-- <el-col :span="8"> -->
        <!-- <el-button type="danger" @click="tan">车辆油耗详情</el-button> -->
        <!-- </el-col> -->
        <!-- <el-col :span="8"> -->
        <!-- <el-button type="success" @click="tan">车辆行驶证书</el-button> -->
        <!-- </el-col> -->
        <!-- <el-col :span="8"> -->
        <!-- <el-button type="primary" @click="tan">历年保险年审</el-button> -->
        <!-- </el-col> -->
        <!-- </el-row> -->
        <!-- </div> -->
        <!-- <div class="biaoge">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="date" label="车辆成本总计" width="180"></el-table-column>
            <el-table-column prop="name" label="2022年" width="180"></el-table-column>
            <el-table-column prop="address" label="2021年"></el-table-column>
            <el-table-column prop="sss" label="2020年"></el-table-column>
          </el-table>
        </div> -->
      </div>
    </el-card>
  </div>
</template>

<script>
import { getList, Endshan, GetRefuelingQr } from '../../../api/carindetail'
export default {
  data() {
    return {
      centerDialogVisible: false,
      id: '', // 车辆id
      list: {}, // 请求回来的数据
      shijian: '', // 处理过后的保险时间
      company: '', // 处理过后的保险公司名称
      pic: '', // 处理图片
      qrIMG: '',
      tableData: [
        {
          date: '保险成本',
          name: '99999',
          address: '99999',
          sss: '99999'
        },
        {
          date: '年审成本',
          name: '99999',
          address: '99999',
          sss: '99999'
        },
        {
          date: '油耗成本',
          name: '99999',
          address: '99999',
          sss: '99999'
        },
        {
          date: '维修成本',
          name: '99999',
          address: '99999',
          sss: '99999'
        }
      ]
    }
  },
  created() {
    this.id = this.$route.query.id // 获取上个页面传过来的id
    console.log(this.id)
    this.Getlists(this.id)
  },
  mounted() {},
  methods: {
    tan() {
      this.centerDialogVisible = true
    },
    // 跳转到上一页
    tos() {
      // this.$router.push({
      //   path: `/carlist`
      // })
      this.$router.back()
    },
    // 删除本车辆
    endshan() {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endshanchu()
          setTimeout(() => {
            this.$router.push({
              path: `/carlist`
            })
          }, 1000)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 客户详情
    async Getlists(id) {
      const { data } = await getList({ id })
      console.log(data);
      this.list = data.data
      if (data.data.insurances == '') {
        this.shijian = '无'
        this.company = '无'
      } else {
        this.shijian = data.data.insurances[0].end_date
        this.company = data.data.insurances[0].company
      }
      if (data.data.auto_photo == '') {
        this.pic =
          ' https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F017bcb58b4db5fa801219c7719b5ff.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663811226&t=55b700144044124648ef8248a1e2678b'
      } else {
        this.pic = data.data.auto_photo[0].url
      }
      if ((data.data.refueling_qr = 'https://qiniu.haiwojiamei.com/')) {
        this.qrIMG =
          'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F017bcb58b4db5fa801219c7719b5ff.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663811226&t=55b700144044124648ef8248a1e2678b'
        this.getRefuelingQrcode(this.id)
      } else {
        this.qrIMG = data.data.refueling_qr
      }
    },
    // 删除接口
    async Endshanchu() {
      const { data } = await Endshan({ id: this.id })
      this.getanpostfun(data, '删除')
    },

    async getRefuelingQrcode(auto_id) {
      const { data } = await GetRefuelingQr( {auto_id} )
      console.log(data, 'datacode')
      this.qrIMG = data.data.info
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  },
  // 局部过滤器
  filters: {
    //
    money(val) {
      return val / 100 + '.' + (val % 100) / 10 + ((val % 100) % 10)
    },
    baoxian(val) {
      // console.log(val)
      if (val.length == 0) {
        return '无'
      } else {
        return val[0].end_date
      }
    },
    baoxians(val) {
      // console.log(val)
      if (val.length == 0) {
        return '无'
      } else {
        return val[0].company
      }
    }
  }
}
</script>

<style scoped>
.top {
  box-sizing: border-box;
  padding: 0px 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.head {
  /* margin-top: 30px; */
  width: 100%;
  /* height: 500px; */
  box-sizing: border-box;
  /* border: 1px solid #000; */
  display: flex;
  padding: 16px 30px;
}
ul {
  /* border: 1px solid red; */
  padding: 0px;
  margin-left: 10px;
  font-weight: bold;
}
li {
  /* border: 1px solid #000; */
  /* margin-left: 20px; */
  list-style: none;
  font-size: 16px;
}
.head_right {
  display: flex;
  margin-left: 80px;
}
.head_right div {
  display: flex;
  margin-left: 80px;
  /* border: 1px solid #000; */
}
.pics {
  width: 300px;
  margin-top: 33px;
}
.foot {
  margin-left: 60px;
  width: 35%;
}
.di {
  display: flex;
  /* border: 1px solid #000; */
}
.biaoge {
  width: 40%;
  margin-left: 90px;
  border: 1px solid #999;
  margin-left: 14%;
  box-sizing: border-box;
  padding-left: 30px;
}
</style>